/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Row, Button, Stack, Dropdown, ButtonGroup } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faFilter, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import {
  selectShipmentFilterParams, createShipment, setGroupBy, filterShipmentDates,
} from '../../redux/slices/shipments';
import { createInvoice } from '../../redux/slices/invoices';
import { FILTER_TEXT } from '../../components/modals/SearchFilter/searchFilters.constants';

import DropdownButton from '../../components/shared/DropdownButton';
import {
  ShipmentDropdownOptions,
  ShipmentListFilters,
} from '../../redux/models/shipment.models';
import { FeatureResource, ResourcePermission } from '../../redux/models/feature.flags.models';
import Permission from '../../components/shared/permissions/Permission';
import ShipmentsModalFilter from '../../components/modals/ShipmentsModal/ShipmentsModalFilter';
import { selectBoardSettings } from '../../redux/slices/settings';
import { ShipmentWritePermissions, TripWritePermissions } from '../../components/shared/permissions/permissions.utils';
import AsyncButton from '../../components/shared/buttons/AsyncButton';
import ButtonSpinner from '../../components/shared/ButtonSpinner';
import ConfirmModal from '../../components/modals/ConfirmModal';
import { createTripFromShipments } from '../../redux/slices/trips/trips';
import Analytics from '../../utils/analytics';


const BUTTON_TEXT = 'Add';
const INVOICE_BTN_TEXT = 'Invoice';
const groupByOptions = Object.values(ShipmentDropdownOptions);
const InvoicePermissions = {
  [FeatureResource.Invoice]: ResourcePermission.Write,
};
interface Props {
  title: string;
  selectedShipments: string[];
  shipmentTypeId?: string;
}

function Header({
  title, selectedShipments, shipmentTypeId
}: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgCode } = useParams();
  const [creatingOptions, setCreatingOptions] = useState(false);
  const [showConfirmInvoice, setShowConfirmInvoice] = useState(false);
  const [showConfirmTrip, setShowConfirmTrip] = useState(false);
  const userGroupByOptions = groupByOptions.filter((option => {
    return option.id !== ShipmentDropdownOptions.type.id;
  }));

  const boardSettings = useSelector(selectBoardSettings);
  const filterParams: ShipmentListFilters = useSelector(selectShipmentFilterParams);
  const [shouldShowFilters, setShouldShowFilters] = useState(false);
  const [isDateBtnOpen, setIsDateBtnOpen] = useState(false);
  const handleDateBtnToggle = (open: boolean) => {
    setIsDateBtnOpen(open);
  };
  const handleMenuToggle = () => {
    const open = !shouldShowFilters;
    setShouldShowFilters(open);
    if (!open) handleDateBtnToggle(open);
  }
  const handleAddShipment = async () => {
    try {
      const typeId = shipmentTypeId || '';
      const shipment = await createShipment(typeId);
      if (shipment.entity_id) {
        navigate(`/${orgCode}/shipments/${shipment.entity_id}`);
      }
    } catch (e: any) {
      const errorMessage = `Couldn't add shipment. ${e.message}. Please contact support if the problem persists.`;
      toast(errorMessage, { type: 'error' });
    } finally {
      setCreatingOptions(false);
    }
  };

  const handleAddInvoice = async () => {
    try {
      setCreatingOptions(true);
      const invoice = await createInvoice(selectedShipments, orgCode);
      if (invoice.entity_id) {
        navigate(`/${orgCode}/invoices/${invoice.entity_id}`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCreatingOptions(false);
    }
  };

  const handleSelectGroupBy = (optionId: string) => {
    dispatch(setGroupBy(optionId));
  };

  const handleLoadShipments = (filters: ShipmentListFilters) => {
    dispatch(filterShipmentDates(filters, boardSettings, shipmentTypeId));
  };

  const handleConfirmInvoice = () => setShowConfirmInvoice(true);

  const handleConfirmCreateTrip = () => setShowConfirmTrip(true);

  const handleAddTrip = async () => {
    try {
      setCreatingOptions(true);
      const trip = await createTripFromShipments(selectedShipments);
      if (trip.entity_id) {
        Analytics.createTripFromShipments(orgCode);
        navigate(`/${orgCode}/trips/${trip.entity_id}`);
      }
    } catch (error) {
      const errorMessage = `Couldn't create trip from shipments. Please contact support if the problem persists.`;
      toast(errorMessage, { type: 'error' });
    } finally {
      setCreatingOptions(false);
    }
  };

  return (
    <Row className="mb-2">
      <Stack direction="horizontal" gap={1}>
        <h3 style={{ width: '50%' }}>{title}</h3>
        <div className="search-filter">
          <Button
            variant="outline-secondary"
            data-cy={`${title}_filter_btn`}
            className="filter-datepicker d-flex align-items-center"
            onClick={handleMenuToggle}
          >
            <Stack gap={1} direction="horizontal">
              <FontAwesomeIcon
                height={10}
                width={10}
                icon={faFilter}
              />
              {' '}
              {FILTER_TEXT}
              <FontAwesomeIcon
                height={10}
                width={10}
                icon={shouldShowFilters ? faChevronUp : faChevronDown}
              />
            </Stack>
          </Button>
          <ShipmentsModalFilter
            filterData={filterParams}
            shouldShowFilters={shouldShowFilters}
            setShouldShowFilters={setShouldShowFilters}
            saveFilters={handleLoadShipments}
            isDateBtnOpen={isDateBtnOpen}
            handleDateBtnToggle={handleDateBtnToggle}
            setIsDateBtnOpen={setIsDateBtnOpen}
          />
        </div>
        <DropdownButton
          selectText="No grouping"
          selectedId={filterParams.grouping}
          options={userGroupByOptions}
          handleSelect={handleSelectGroupBy}
        />
        {shipmentTypeId && (
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
              {creatingOptions ? <ButtonSpinner variant='light' /> : 'Options'}
            </Dropdown.Toggle>
            {/* <Dropdown.Toggle split variant="primary" id="add-trip-dropdown" data-cy="boards-add_option_btn" /> */}
            <Dropdown.Menu>
              <Permission resources={InvoicePermissions}>
                <Dropdown.Item
                  onClick={handleConfirmInvoice}
                  data-cy="shipments-create_invoice_btn"
                >Invoice</Dropdown.Item>
              </Permission>
              <Permission resources={TripWritePermissions}>
                <Dropdown.Item
                  onClick={handleConfirmCreateTrip}
                  data-cy="shipments-create_trip_btn"
                >Create Trip</Dropdown.Item>
              </Permission>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {!shipmentTypeId && (
          <Permission resources={InvoicePermissions}>
            <AsyncButton
              title={INVOICE_BTN_TEXT}
              variant="outline-primary"
              spinner="primary"
              disabled={!selectedShipments.length}
              handleClick={handleAddInvoice}
            />
          </Permission>
        )}
        <Permission resources={ShipmentWritePermissions}>
          <AsyncButton title={BUTTON_TEXT} spinner="light" handleClick={handleAddShipment} />
        </Permission>
      </Stack>
      {showConfirmInvoice && (
        <ConfirmModal
          title="Create Invoice"
          details="Would you like to create an invoice from the selected shipments? This will create one invoice with shipments as the line items"
          btnTitle="Create Invoice"
          saveDisabled={creatingOptions || selectedShipments.length === 0}
          shouldShowModal
          isSaving={creatingOptions}
          handleSave={handleAddInvoice}
          cancelSave={() => setShowConfirmInvoice(false)}
        />
      )}
      {showConfirmTrip && (
        <ConfirmModal
          title="Create Trip"
          details="Would you like to create a trip from the selected shipments? Moves will be added for the shipment origin and destination if they are filled out."
          btnTitle="Create Trip"
          saveDisabled={creatingOptions || selectedShipments.length === 0}
          shouldShowModal
          isSaving={creatingOptions}
          handleSave={handleAddTrip}
          cancelSave={() => setShowConfirmTrip(false)}
        />
      )}
    </Row>
  );
}

Header.defaultProps = {
  shipmentTypeId: undefined,
}

export default Header;