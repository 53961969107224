/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
/* eslint-disable no-var */
/* eslint-disable no-async-promise-executor */
/* eslint-disable no-useless-catch */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { AquadashServiceApiProvider } from '../../services/AquadashServiceApiProvider';

import {
  removeItemFromLocalStorage,
  saveValueToLocalStorage,
  getValueFromLocalStorage,
  getValueFromSessionStorage,
  saveValueToSessionStorage,
} from '../../utils/core.utils';

import { SETTINGS_MANAGER, SETTINGS_NAMES, getSettingName } from '../../constants/core.constants';
import { SETTINGS } from './slices';
import { Address, Driver, Fleet, Group, INITIAL_SETTINGS, Tag, UOM, Vehicle, initialSettingsState, NoteTemplateData, NoteTemplate, TripTemplate, ShippingLine, GroupData, Cost } from '../models/settings.models';
import { AppState, DispatchProps } from '../models/state.models';
import { Board, BoardData, BoardSettings, TripTableColumns } from '../models/board.models';
import { EntityContainer, createContainer } from '../models/core.models';
import { filterDriversByTags, getPostSearchBody, isFeatureEnabled } from './settings.utils';
import { SettingsApi } from '../../services/SettingsApi';
import { Contact } from '../models/contact.models';
import { BillingItem, BillingType } from '../models/billing.models';
import { defaultDispatchTemplate } from '../../components/text/MoveNotes.utils';
import { BoardApi, GroupApi, NotesTemplateApi, TripTemplateApi } from '../../services/services';
import { ShipmentType } from '../models/shipment.models';
import { TRIP_TABLE_HEADERS } from '../../pages/boards/TripTable/constants/tripTable.constants';
import { BILLING_SHIPMENT_TABLE_HEADERS } from '../../pages/shipments/ShipmentsTable/constants/shipmentTable.constants';

const SLICE_NAME = SETTINGS;
const FEATURE_FLAG_KEY = 'feature-flags';

const slice = createSlice({
  name: SLICE_NAME,
  initialState: initialSettingsState,
  reducers: {
    setSettings(state, action) {
      state.settings = action.payload;
      state.isLoaded = true;
    },
    setAreSettingsLoading(state, action) {
      state.areSettingsLoading = action.payload;
    },
    setSetting(state, action) {
      const { name, data } = action.payload;
      const newSettings = {
        list: data,
        lastUpdated: `${new Date()}`,
      };
      const appSettings = {
        ...state.settings,
        [name]: newSettings,
      };
      state.settings = appSettings;
    },
    addSettingsItem(state, action) {
      const { name, data } = action.payload;
      const items = state.settings[name].list || [];
      const list = [...items, data];
      const newSettings = {
        list,
        lastUpdated: `${new Date()}`,
      };
      const appSettings = {
        ...state.settings,
        [name]: newSettings,
      };
      state.settings = appSettings;
    },
    updateSettingsItem(state, action) {
      const { name, data } = action.payload;
      const items = state.settings[name].list || [];
      const list = items.map((item: any) => {
        return item.entity_id === data.entity_id ? data : item;
      });
      const newSettings = {
        list,
        lastUpdated: `${new Date()}`,
      };
      const appSettings = {
        ...state.settings,
        [name]: newSettings,
      };
      state.settings = appSettings;
    },
  },
});

export const {
  addSettingsItem,
  setSettings,
  setAreSettingsLoading,
  setSetting,
  updateSettingsItem,
} = slice.actions;

export const getSettings = () => async (dispatch: DispatchProps) => {
  dispatch(setAreSettingsLoading(true));
  try {
    const { data: appData } = await AquadashServiceApiProvider.getAppData();
    const result = Object.values(SETTINGS_NAMES)
      .reduce((settingObj, settingName) => {
        const settingItemData = appData.data[settingName];
        const storedSetting = settingItemData;
        return {
          ...settingObj,
          [settingName]: { lastUpdated: `${new Date()}`, list: storedSetting },
        };
      }, INITIAL_SETTINGS);
    dispatch(setSettings(result));
  } catch (e: any) {
    if (e.code === 'invalid_authorization_header') {
      console.log(e);
      console.log('re direct to login');
      const errorMessage = `Your session has expired. Please sign in again.`;
      toast(errorMessage, { type: 'error' });
    } else {
      const errorMessage = `Couldn't get main settings. Please contact support if the problem persists.`;
      toast(errorMessage, { type: 'error' });
    }
  } finally {
    dispatch(setAreSettingsLoading(false));
  }
};

/**
* Sync settings from the database. If Samsara data sync from
* @param {String} name settings key
* @param {Boolean} shouldRefresh override to hit the server
* @returns dispatch action
*/
export const getSettingByName = (name: string, showSuccess = true) => async (dispatch: DispatchProps) => {
  const settingsName = getSettingName(name);
  try {
    const { data: { data } } = await SETTINGS_MANAGER[name](); 
    dispatch(setSetting({ name, data }));
    if (showSuccess) {
      toast(`${settingsName} settings were updated`, { type: 'success' });
    }
  } catch (e: any) {
    const errorMessage = `Couldn't get ${settingsName}. ${e.description}. Please contact support if the problem persists.`;
    toast(errorMessage, { type: 'error' });
  }
};

export const getLocalFeatureFlags = () => {
  Object.values(SETTINGS_NAMES).forEach((setting) => removeItemFromLocalStorage(setting));
  return getValueFromLocalStorage(FEATURE_FLAG_KEY);
};

export const fetchFeatureFlags = async () => {
  try {
    const response = await SettingsApi.getFeatureFlags();
    if (response.status === 200) {
      const featureReponse = response.data?.data || [];
      const featurData = featureReponse.reduce((store: any, feature: any) => {
        const key = feature?.data?.key || '';
        const gd = feature?.data || {};
        return {
          ...store,
          [key]: {
            ...gd,
            is_enabled: isFeatureEnabled(gd),
          },
        }
      }, {});
      saveValueToLocalStorage(FEATURE_FLAG_KEY, featurData);
      return featurData;
    }
    return {};
  } catch (error) {
    console.log("Coulnd't fetch feature flags");
    console.log(error);
    return {};
  }
};

export const getUserOrganisations = async () => {
  return SettingsApi.getUserOrgs();
};

export const createGroup = async (position: number, board: Board) => {
  const data: GroupData = {
    name: 'New Group',
    board,
    position,
    trip_template_ids: [],
  };
  return GroupApi.create(data);
};

export const saveGroup = async (group: Group) => {
  return GroupApi.update(group);
}

export const createBoard = async () => {
  const data: BoardData = {
    name: 'New Board',
    integrations: [],
    table_config: {
      driver_table: TRIP_TABLE_HEADERS,
      group_table: TRIP_TABLE_HEADERS,
      shipment_table: BILLING_SHIPMENT_TABLE_HEADERS
    },
    driver_tags: [],
    fleet_tags: [],
    vehicle_tags: [],
    note_templates: [],
  };
  return BoardApi.create(data);
};

export const saveBoard = async (board: Board) => {
  return BoardApi.update(board);
}

export const deleteBoard = async (board: Board) => {
  return BoardApi.delete(board);
}

export const createDispatchTemplate = (name: string) => {
  const data: NoteTemplateData = {
    name,
    notes: defaultDispatchTemplate,
  };
  return NotesTemplateApi.create(data);
}

export const updateDispatchTemplate = (template: NoteTemplate) => {
  return NotesTemplateApi.update(template);
}

export const getDispatchTemplate = (templateId: string) => {
  return NotesTemplateApi.getById(templateId);
}

export const deleteDispatchTemplate = (template: NoteTemplate) => {
  return NotesTemplateApi.delete(template);
}

export const updateTripTemplate = (template: TripTemplate) => {
  return TripTemplateApi.update(template);
}

export const getTripTemplate = (templateId: string) => {
  return TripTemplateApi.getById(templateId);
}

export const deleteTripTemplate = (template: TripTemplate) => {
  return TripTemplateApi.delete(template);
}

export const getUsers = (orgId: string) => {
  return SettingsApi.getUsers(orgId);
}

export const searchSettings = (settingName: string, searchStr: string, apiQueryParam: string, apiQueryParam2?: string) => {
  const filters = getPostSearchBody(apiQueryParam, searchStr, apiQueryParam2);
  return AquadashServiceApiProvider.findSettings(settingName, filters);
}

const getStorageKey = (board: Board) => `board-width-${board.entity_id}`;

export const updateColWidths = (board: Board) => {
  const key = getStorageKey(board);
  const cols = board.data.table_config.group_table || [];
  saveValueToSessionStorage(key, cols);
};

export const getColWidths = (board: Board): TripTableColumns[] => {
  const key = getStorageKey(board);
  return getValueFromSessionStorage(key) || [];
};

export const selectSettings = (state: AppState) => state[SLICE_NAME].settings;
export const selectAreSettingsLoading = (state: AppState) => state[SLICE_NAME].areSettingsLoading;

export const selectBoards = (state: AppState): Board[] => state[SLICE_NAME].settings.boards?.list || [];
export const selectBoard = (state: AppState, boardId: string | undefined): Board | undefined => selectBoards(state).find((board: Board) => board.entity_id === boardId);
export const selectBoardsObject = (state: AppState) => {
  const container: EntityContainer<Board> = {};
  selectBoards(state).forEach((board: Board) => {
    container[board.entity_id] = board;
  });
  return container;
};

export const selectGroups = (state: AppState): Group[] => state[SLICE_NAME].settings.groups?.list || [];
export const selectGroupsByBoardId = (state: AppState, boardId: string) => selectGroups(state).filter((
  group: Group
) => group.data.board_id === boardId).sort((a: Group, b: Group) => (a.data?.position || 0) - (b.data?.position || 0));
export const selectGroupsObject = (state: AppState) => {
  const container: EntityContainer<Group> = {};
  selectGroups(state).forEach((group: Group) => {
    container[group.entity_id] = group;
  });
  return container;
};

export const selectContacts = (state: AppState): Contact[] => state[SLICE_NAME].settings.contacts?.list || [];
export const selectContactsObject = (state: AppState) => {
  const obj: EntityContainer<Contact> = {};
  const contacts = selectContacts(state);
  contacts.forEach((contact: any) => {
    obj[contact.entity_id] = contact;
  });
  return obj;
};

export const selectDriversByTags = (state: AppState, tags: Tag[]): Driver[] => {
  var drivers = [...state[SLICE_NAME].settings.drivers.list];
  return filterDriversByTags(drivers, tags);
}

export const selectDriversObject = (state: AppState) => {
  const obj: EntityContainer<Driver> = {};
  state[SLICE_NAME].settings.drivers.list.forEach((driver: any) => {
    obj[driver.entity_id] = driver;
  });
  return obj;
};

export const selectAddresses = (state: AppState): Address[] => state[SLICE_NAME].settings.addresses?.list || [];
export const selectAddressesObject = (state: AppState) => {
  const obj: EntityContainer<Address> = {};
  state[SLICE_NAME].settings.addresses.list.forEach((address: any) => {
    obj[address.entity_id] = address;
  });
  return obj;
};

export const selectCosts = (state: AppState): Cost[] => state[SLICE_NAME].settings.costs?.list || [];
export const selectCostsObject = (state: AppState) => {
  const obj: EntityContainer<Cost> = {};
  selectCosts(state).forEach((cost) => {
    obj[cost.entity_id] = cost;
  });
  return obj;
};

export const selectVehicles = (state: AppState): Vehicle[] => state[SLICE_NAME].settings.vehicles?.list || [];
export const selectVehiclesObject = (state: AppState) => {
  const obj: EntityContainer<Vehicle> = {};
  selectVehicles(state).forEach((vehicle) => {
    obj[vehicle.entity_id] = vehicle;
  });
  return obj;
};

export const selectFleet = (state: AppState): Fleet[] => state[SLICE_NAME].settings[SETTINGS_NAMES.FLEET]?.list || [];
export const selectFleetObject = (state: AppState) => {
  const obj: EntityContainer<Fleet> = {};
  selectFleet(state).forEach((fleet) => {
    obj[fleet.entity_id] = fleet;
  });
  return obj;
};

export const selectBillingTypes = (state: AppState): BillingType[] => state[SLICE_NAME].settings[SETTINGS_NAMES.BILLING_TYPES]?.list || [];

export const selectBillingTypesByCustomer = (state: AppState, contactId: string) => selectBillingTypes(state).filter(
  (billingType: any) => billingType.data?.customer_id === contactId,
);

export const selectBillingTypeObject = (state: AppState) => selectBillingTypes(state).reduce((container: EntityContainer<BillingType>, billingType) => ({
  ...container,
  [billingType.entity_id]: billingType,
}), {});

export const selectBillingItems = (state: AppState): BillingItem[] => state[SLICE_NAME].settings[SETTINGS_NAMES.BILLING_ITEMS]?.list || [];
export const selectBillingItemsObject = (state: AppState): EntityContainer<BillingItem> => {
  const rates = selectBillingItems(state);
  const obj: EntityContainer<BillingItem> = {};
  rates.forEach((fleet) => {
    obj[fleet.entity_id] = fleet;
  });
  return obj;
}
export const selectUoms = (state: AppState): UOM[] => state[SLICE_NAME].settings[SETTINGS_NAMES.UOMS].list || [];
export const selectTemplates = (state: AppState) => state[SLICE_NAME].settings[SETTINGS_NAMES.TRIP_TEMPLATES].list || [];
export const selectShipmentTypes = (state: AppState): ShipmentType[] => state[SLICE_NAME].settings[SETTINGS_NAMES.SHIPMENT_TYPES]?.list || [];
export const selectWeightUoms = (state: AppState) => selectUoms(state).filter((item: any) => item.data?.dimension === 'mass');
export const selectNoteTemplates = (state: AppState): NoteTemplate[] => state[SLICE_NAME].settings[SETTINGS_NAMES.NOTE_TEMPLATES]?.list || [];
export const selectProducts = (state: AppState) => state[SLICE_NAME].settings[SETTINGS_NAMES.PRODUCTS].list || [];
export const selectTags = (state: AppState): Tag[] => state[SLICE_NAME].settings[SETTINGS_NAMES.TAGS].list || [];
export const selectTagObject = (state: AppState) => createContainer(selectTags(state))
export const selectUomObject = (state: AppState) => createContainer(selectUoms(state))

export const selectShippingLines = (state: AppState): ShippingLine[] => state[SLICE_NAME].settings.shipping_lines?.list || [];
export const selectShippingLinesObject = (state: AppState) => {
  const obj: EntityContainer<ShippingLine> = {};
  state[SLICE_NAME].settings.shipping_lines.list.forEach((line: ShippingLine) => {
    obj[line.entity_id] = line;
  });
  return obj;
};

export const selectShipmentTypesObject = (state: AppState) => {
  const obj: EntityContainer<ShipmentType> = {};
  const types = selectShipmentTypes(state);
  types.forEach((line) => {
    obj[line.entity_id] = line;
  });
  return obj;
};

export const selectBoardSettings = (state: AppState): BoardSettings => ({
  addresses: selectAddressesObject(state),
  contacts: selectContactsObject(state),
  billingTypes: selectBillingTypeObject(state),
  drivers: selectDriversObject(state),
  fleet: selectFleetObject(state),
  groups: selectGroupsObject(state),
});

export const selectShipmentBoardSettings = (state: AppState) => ({
  addresses: selectAddressesObject(state),
  contacts: selectContactsObject(state),
  billingTypes: selectBillingTypeObject(state),
});

export const selectIsSettingsLoaded = (state: AppState) => state[SLICE_NAME].isLoaded;

export const settingsReducer = slice.reducer;
