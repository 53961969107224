enum UpdateType {
  Feature = 'Feature',
  Fix = 'Fix',
  LightFeature = 'Light Feature',
  Update = 'Update',
}

enum Stage {
  Experimental = 'Experimental',
  Beta = 'Beta'
}

enum MediaType {
  Image = 'image',
  Video = 'video',
}

interface Media {
  link: string;
  type: MediaType,
}

export interface Version {
  version: string;
  date: string;
  media?: Media;
  stage?: Stage;
  type?: UpdateType;
  updates: string[];
}

export const updateArr: Version[] = [
  {
    version: '2.21',
    date: '24-11-13',
    type: UpdateType.Feature,
    updates: [
      'Add shipment boards'
    ],
  },
  {
    version: '2.20',
    date: '24-11-12',
    type: UpdateType.Update,
    updates: [
      'Add taxes to invoice line items and rates'
    ],
  },
  {
    version: '2.19',
    date: '24-11-05',
    type: UpdateType.Update,
    updates: [
      'Update driver details'
    ],
  },
  {
    version: '2.18.1',
    date: '24-11-04',
    type: UpdateType.Update,
    updates: [
      'Add detail label to address input',
      'Update columns on trip planner',
      'Update driver filters on trip planner',      
      'Open trip from drivers tab'
    ],
  },
  {
    version: '2.18',
    date: '24-10-28',
    type: UpdateType.Fix,
    updates: [
      'Performance improvements for moves table',
    ],
  },
  {
    version: '2.17',
    date: '24-10-22',
    type: UpdateType.Update,
    updates: [
      'Update trip template editor',
    ],
  },
  {
    version: '2.16',
    date: '24-10-19',
    type: UpdateType.Update,
    updates: [
      'Add driver requirements to shipments',
      'Fix route labels on trip summary'
    ],
  },
  {
    version: '2.15.2',
    date: '24-10-15',
    type: UpdateType.Update,
    updates: [
      'Performance improvements and fixes.'
    ],
  },
  {
    version: '2.15.1',
    date: '24-10-15',
    type: UpdateType.Update,
    updates: [
      'Performance improvements and fixes.'
    ],
  },
  {
    version: '2.15',
    date: '24-09-27',
    type: UpdateType.Feature,
    updates: [
      'Add auto assign drivers to trip plan'
    ],
  },
  {
    version: '2.14.2',
    date: '24-09-26',
    type: UpdateType.Update,
    updates: [
      'Update planner page',
      'Add driver trip history page'
    ],
  },
  {
    version: '2.14.1',
    date: '24-09-20',
    type: UpdateType.Update,
    updates: [
      'Fix for logging in on expired sessions.',
    ],
  },
  {
    version: '2.14',
    date: '24-09-17',
    type: UpdateType.Update,
    updates: [
      'Add shipment assignee to dashboard and shipment filter list',
    ],
  },
  {
    version: '2.13',
    date: '24-09-12',
    type: UpdateType.Update,
    updates: [
      'Add assignee field to shipments',
    ],
  },
  {
    version: '2.12',
    date: '24-09-04',
    type: UpdateType.Update,
    updates: [
      'Add reefer settings to shipment page',
    ],
  },
  {
    version: '2.11',
    date: '24-08-29',
    type: UpdateType.Feature,
    updates: [
      'Apply the availability of one driver to another',
    ],
  },
  {
    version: '2.10',
    date: '24-08-29',
    type: UpdateType.Feature,
    updates: [
      'Add history of trips and shipments to show updates',
      'Updates for deleting moves'
    ],
  },
  {
    version: '2.9',
    date: '24-08-28',
    type: UpdateType.LightFeature,
    updates: [
      'Add toggle for selecting drivers on trip planner'
    ],
  },
  {
    version: '2.8.2',
    date: '24-08-27',
    type: UpdateType.Update,
    updates: [
      'Fix for update b train, home yard and costs on driver details page'
    ],
  },
  {
    version: '2.8.1',
    date: '24-08-25',
    type: UpdateType.Feature,
    updates: [
      'Updates to dispatching trip plan, groups and driver.',
      'Add multi dispatch notifications',
      'Updates for board dates',
      'Timezone updates for creating trips',
      'Fix for rendering trailer names on dispatch boards',
    ],
  },
  {
    version: '2.8.0',
    date: '24-08-19',
    type: UpdateType.Feature,
    updates: [
      'Creating trips from templates in the background.',
    ],
  },
  {
    version: '2.7.3',
    date: '24-08-19',
    updates: [
      "Show a warning instead of blocking trips from being saved when move dates aren't in chronological order",
      'Updates to make searching custom date ranges easier',
      'Search invoice no. by text instead of dropdown',
      'Update app timezone offsets'
    ],
  },
  {
    version: '2.7.2',
    date: '24-07-22',
    updates: [
      'Add company timezone offset when creating trips and shipments from templates',
    ],
  },
  {
    version: '2.7.1',
    date: '24-07-09',
    updates: [
      'Updates for validating moves when saving trips',
      'Under the hood improvements for feature flags'
    ],
  },
  {
    version: '2.7.0',
    date: '24-06-28',
    updates: [
      'You can now assign move documents as additional documents to shipments.',
      'Added error message when existing shipments failed to load'
    ],
  },
  {
    version: '2.6.19',
    date: '24-06-19',
    updates: [
      'New: Move timeliness report',
      'Update success message on sync settings',
      'Fix load more spinner on shipment list'
    ],
  },
  {
    version: '2.6.18',
    date: '24-06-06',
    updates: [
      'Add shipment list pagination'
    ],
  },
  {
    version: '2.6.17',
    date: '24-06-03',
    updates: [
      'Add subtitle to address fields',
      'Update move timeliness graph to include board selection'
    ],
  },
  {
    version: '2.6.16',
    date: '24-05-31',
    updates: [
      'Include default notes from boards when creating trips.',
    ],
  },
  {
    version: '2.6.15',
    date: '24-05-30',
    updates: [
      'Fix for opening moves containing deleted Samsara addresses.',
    ],
  },
  {
    version: '2.6.14',
    date: '24-05-24',
    updates: [
      'Update address fields on moves table shipments.',
    ],
  },
  {
    version: '2.6.13',
    date: '24-05-01',
    updates: [
      'Add shipments tab to dispatch board',
    ],
  },
  {
    version: '2.6.12',
    date: '24-04-23',
    updates: [
      'Fix: Update for attaching documents to shipments removing related trips',
      'Fix: Invoices page filter updates',
    ],
  },
  {
    version: '2.6.11',
    date: '24-04-17',
    updates: [
      'New: Add integrations page',
      'Update: Save default fuel price',
      'Update: Fix for sidebar permissions',
    ],
  },
  {
    version: '2.6.10',
    date: '24-03-19',
    updates: [
      'Update: Add outside cartage to dashboard',
      'Update: Performance improvements and bug fixes',
    ],
  },
  {
    version: '2.6.9',
    date: '24-03-14',
    updates: [
      'Update: Update trip status after dispatching trips by email and sms',
      'Update: Add total moves to dashboard',
    ],
  },
  {
    version: '2.6.8',
    date: '24-03-12',
    updates: [
      'Fix: Fix validation on samsara addresses.',
    ],
  },
  {
    version: '2.6.7',
    date: '24-03-06',
    updates: [
      'New: Dispatch Drivers via email',
      'Update: Dashboard v1.2, Added shipment requests and appoinments (moves with appointment toggled)',
      'Beta: Dispatch Drivers via sms',
      'Beta: Improved distance calculations on trip costs',
    ],
  },
  {
    version: '2.6.6',
    date: '24-02-28',
    updates: [
      'Update: Add requested status to shipments',
      'Beta: Projected Trip Costs',
    ],
  },
  {
    version: '2.6.5',
    date: '24-02-28',
    updates: [
      "Update: Load Type has been updated to 'Quote templates' on shipment card",
      'New: Manage drivers from app settings',
      'Update: Move Timeliness dashboard metric',
      'Update: Fixes for ETA Email',
    ],
  },
  {
    version: '2.6.4',
    date: '24-02-19',
    updates: [
      'Update: Duplicate trips and shipments',
      'Update: Delete trips and shipments from their respective menus',
      'Update: Paginate invoice search',
      'Update: Update customers and rates from settings page',
      'Update: Download invoice csv',
    ],
  },
  {
    version: '2.6.3',
    date: '24-02-14',
    updates: [
      'Fix for grouping trip planner',
    ],
  },
  {
    version: '2.6.2',
    date: '24-02-12',
    updates: [
      'Fix: Fix for assigning planned trips to drivers not on the list',
    ],
  },
  {
    version: '2.6.1',
    date: '24-02-07',
    updates: [
      'Update: Add empty moves stat to dashboard',
    ],
  },
  {
    version: '2.6.0',
    date: '24-02-02',
    updates: [
      'Update: Performance improvements for trip planner',
      'New: Create trips from unassigned shipments',
    ],
  },
  {
    version: '2.5.12',
    date: '24-01-30',
    updates: [
      'Update: Add ability to create empty trips on dispatch boards',
      'Fix: Dashboard update for showing drivers with no availability',
      'Fix: Assigning draft drivers',
    ],
  },
  {
    version: '2.5.11',
    date: '24-01-26',
    updates: [
      'Update: Add and edit groups from dispatch boards',
      'Update: Add default table columns when creating a new dispatch board',
      'Update: Create templates from the shipment page',
    ],
  },
  {
    version: '2.5.10',
    date: '24-01-25',
    updates: [
      'Update: Update help centre link',
    ],
  },
  {
    version: '2.5.9',
    date: '24-01-24',
    updates: [
      'Update: Add TBD departure and arrival times to moves',
      'Update: Add and edit groups from within the dispatch board',
    ],
  },
  {
    version: '2.5.8',
    date: '24-01-22',
    updates: [
      'New: Add Dashboard Metrics',
      'Update: Update add and update board name',
    ],
  },
  {
    version: '2.5.7',
    date: '24-01-04',
    updates: [
      'Update: Add shipping line. ',
      'Fixes: Fixes for shipment details fields',
      'Update trailer field in move details panel',
    ],
  },
  {
    version: '2.5.6',
    date: '23-12-12',
    updates: [
      'New: Add custom shipment types with required fields',
      'Update: Invoicing permissions',
      'Update: Document images are now draggable',
      'Fix: Headings for groupeded options on the shipments page',
    ],
  },
  {
    version: '2.5.5',
    date: '23-11-30',
    updates: [
      'Update: Performance improvements and bug fixes',
    ],
  },
  {
    version: '2.5.4',
    date: '23-11-29',
    updates: [
      'Update: Under the hood improvements to login experience',
    ],
  },
  {
    version: '2.5.3',
    date: '23-11-24',
    updates: [
      'Update: Add outside cartage to trip planner',
      "Update: 'Ready For Dispatch' trip status",
      'Update: Notes indicator',
      'Update: Save planner filters',
      'Update: Add shipment assignment modal',
    ],
  },
  {
    version: '2.5.2',
    date: '23-10-26',
    updates: [
      'Beta: Trip Planner 1.1',
      'Beta: Shipment Documents Panel',
      'Fix: Move notes delay',
    ],
  },
  {
    version: '2.5.1',
    date: '23-10-12',
    updates: [
      'New: Trips dispatched through samsara will now contain documents submitted by the driver.',
      'Update: Permissions',
      'Update: Driver table columns',
      'Update: Add accessorial filter when adding rates to shipments',
      'Fix: Add note templates to created trips',
      'Fix: Searching custom dates when adding existing shipments to trips',
    ],
  },
  {
    version: '2.5.0',
    date: '23-09-28',
    updates: [
      'Update: Add new grouping options to shipment list',
    ],
  },
  {
    version: '2.4.9',
    date: '23-09-16',
    updates: [
      'New: Add timeliness to moves and board',
      'Update: Add multiple trailers to moves',
      'Update: Add mentions to dispatch notes',
      'Update: Add trip templates to app settings',
      'Update: Add Custom accessorials',
      'Update: Add currency to invoice details page',
      'Fix: Fix appt time on board to match date filters',
      'Fix: Error when double clicking dispatch button',
    ],
  },
  {
    version: '2.4.8',
    date: '23-09-05',
    updates: [
      'Update: Remove temp from moves',
      'Update: Increment time by 15 mins when adding new moves',
      'Update: Add container # to shipment list',
      'Update: Update shipment list filters',
    ],
  },
  {
    version: '2.4.7',
    date: '23-08-17',
    updates: [
      'Update: Planner table engine and style upgrades',
    ],
  },
  {
    version: '2.4.6',
    date: '23-08-16',
    updates: [
      'Update: Added support for users working across multiple organisations',
    ],
  },
  {
    version: '2.4.5',
    date: '23-08-08',
    updates: [
      'Update: Dispatch board to include shipment container numbers',
      'Update: Moves table to select shipment by container number if present',
      'Update: Filter existing shipments by container number',
    ],
  },
  {
    version: '2.4.4',
    date: '23-08-08',
    updates: [
      'Update: Add customer and Quote Template to table cols',
      'Fix: Saving table column widths on the dispatch board',
    ],
  },
  {
    version: '2.4.3',
    date: '23-08-08',
    updates: [
      'Update: Update resource permissions and scopes',
    ],
  },
  {
    version: '2.4.2',
    date: '23-07-31',
    updates: [
      'Update: Add date range filters to dispatch board',
      'Update: Routing updates',
    ],
  },
  {
    version: '2.4.1',
    date: '23-07-31',
    updates: [
      'Beta: New trip table layout',
      'Beta: Resizable trip table columns',
    ],
  },
  {
    version: '2.4.0',
    date: '23-07-22',
    updates: [
      'Beta: Suggested driver availability',
    ],
  },
  {
    version: '2.3.9',
    date: '23-07-22',
    updates: [
      'New: Add feature flags for beta users',
    ],
  },
  {
    version: '2.3.8',
    date: '23-07-21',
    updates: [
      'New: Config dispatch board columns in the settings page.',
    ],
  },
  {
    version: '2.3.7',
    date: '23-07-21',
    updates: [
      'Fix: Update for checking logged out user permissions',
    ],
  },
  {
    version: '2.3.6',
    date: '23-07-20',
    updates: [
      'New: Add trip planner tab to dispatch board',
    ],
  },
  {
    version: '2.3.5',
    date: '23-07-19',
    updates: [
      'Fix: Update: Remove all app settings from local storage',
    ],
  },
  {
    version: '2.3.4',
    date: '23-07-13',
    updates: [
      'Fix: fix for creating new shipments on the trip details page',
    ],
  },
  {
    version: '2.3.3',
    date: '23-07-10',
    updates: [
      'Update: Shipment notes added to dispatch board',
      'Update: B.O.L added to shipment',
    ],
  },
  {
    version: '2.3.2',
    date: '23-07-01',
    updates: [
      'Fix: Fix app settings storage data',
    ],
  },
  {
    version: '2.3.1',
    date: '23-06-20',
    updates: [
      'New: You can now dispatch samsara routes through trip details page',
      'New: Select which appointment shows on the dispatch board',
      'Fix: Fix for saving trips on shipment details page',
    ],
  },
  {
    version: '2.3.0',
    date: '23-06-15',
    updates: [
      'Update: Change shipment status order',
    ],
  },
  {
    version: '2.2.7',
    date: '23-05-13',
    updates: [
      'Update: Remove billing rules from settings',
      'Update: Use local search when filtering vehicles by board tag',
    ],
  },
  {
    version: '2.2.6',
    date: '23-05-12',
    updates: [
      'Fix: Remove templates from local state after delete event.',
    ],
  },
  {
    version: '2.2.5',
    date: '23-05-11',
    updates: [
      'Fix: Update checks for board tags on trip details page.',
    ],
  },
  {
    version: '2.2.4',
    date: '23-05-10',
    updates: [
      'Fix: Return all board items if no tags are present.',
    ],
  },
  {
    version: '2.2.3',
    date: '23-05-10',
    updates: [
      'Update: Vehicle, trailer and driver board filters can now be configured through hopper.',
      'Update: ETA column on boards now only includes times for drops and deliveries.',
      'Update: Updated driver view on boards to make it clearer which drivers have been assigned trips',
    ],
  },
  {
    version: '2.2.2',
    date: '23-05-09',
    updates: [
      'Update: Add support for group templates configured through hopper',
    ],
  },
  {
    version: '2.2.1',
    date: '23-05-09',
    updates: [
      'Update: Added move ETA support to boards.',
      'Update: Finance now uses invoice permissions which can be configured through hopper.',
      'Feature: Added support for users belonging to multiple organisations.'
    ],
  },
  {
    version: '2.1.3',
    date: '23-05-02',
    updates: [
      'Fix: Update identity provider on login.',
    ],
  },
  {
    version: '2.1.2',
    date: '23-04-29',
    updates: [
      'Update: Add support for 3 decimal places on invoice rates',
    ],
  },
  {
    version: '1.01.33',
    date: '23-03-29',
    updates: [
      'Feature: Update Product list dropdown to use API',
    ],
  },
  {
    version: '1.01.32',
    date: '23-03-21',
    updates: [
      'Feature: Change shipments and invoices filters to a modal',
    ],
  },
  {
    version: '1.01.31',
    date: '23-03-16',
    updates: [
      'Feature: Change shipments & invoices date search to a date range dropdown',
    ],
  },
  {
    version: '1.01.30',
    date: '23-03-15',
    updates: [
      'Feature: Dispatchers can view UOM & Fee rate pecentage to 3 decimals',
    ],
  },
  {
    version: '1.01.29',
    date: '23-03-13',
    updates: [
      'Feature: Have drivers tab show the drivers with trips first',
    ],
  },
  {
    version: '1.01.28',
    date: '23-03-13',
    updates: [
      'Feature: Add route to shipments on moves table',
    ],
  },
  {
    version: '1.01.27',
    date: '23-03-10',
    updates: [
      'Feature: All shipment status shows on Trip Shipment Cards',
    ],
  },
  {
    version: '1.01.26',
    date: '23-03-10',
    updates: [
      'Feature: Update product form - Product input to dropdown, larger notes, DG next to product',
    ],
  },
  {
    version: '1.01.25',
    date: '23-03-10',
    updates: [
      'Feature: Add warnings for removing shipments on invoices',
    ],
  },
  {
    version: '1.01.24',
    date: '23-03-09',
    updates: [
      'Feature: Ability to create multiple invoices from the Shipments page',
    ],
  },
  {
    version: '1.01.23',
    date: '23-03-01',
    updates: [
      'Feature: Add invoice details page',
    ],
  },
  {
    version: '1.01.22',
    date: '23-02-27',
    updates: [
      'Feature: Add invoices to navigation',
      'Bug Fix: update node.js version',
    ],
  },
  {
    version: '1.01.21',
    date: '23-02-23',
    updates: [
      'Feature: Add billing components shipment details',
    ],
  },
  {
    version: '1.01.20',
    date: '23-02-15',
    updates: [
      'Bug Fix: Board shipments displaying properly',
    ],
  },
  {
    version: '1.01.19',
    date: '23-02-13',
    updates: [
      'Bug Fix: Update Quote Templates dropdown to not use contact API',
    ],
  },
  {
    version: '1.01.18',
    date: '23-02-08',
    updates: [
      'Bug Fix: Trip Details Shipment Modal - Add existing shipments & update shipments table',
    ],
  },
  {
    version: '1.01.17',
    date: '23-02-02',
    updates: [
      'Feature: Add dry toggle next to temperature on shipment form & add temp to trips table',
    ],
  },
  {
    version: '1.01.16',
    date: '23-01-31',
    updates: [
      'Feature: Add attachments for shipments',
    ],
  },
  {
    version: '1.01.15',
    date: '23-01-20',
    updates: [
      'Bug Fix: Update Settings sync button path to /sync',
    ],
  },
  {
    version: '1.01.14',
    date: '23-01-16',
    updates: [
      'Bug Fix: Remove storing trips and shipments in session storage',
    ],
  },
  {
    version: '1.01.13',
    date: '23-01-09',
    updates: [
      'Feature: Add "Training" to status & capitalize status on shipments table',
    ],
  },
  {
    version: '1.01.12',
    date: '22-12-29',
    updates: [
      'Feature: Add trailer & action input fields for Trailer Change modal table',
    ],
  },
  {
    version: '1.01.11',
    date: '22-12-20',
    updates: [
      'Feature: Trailer Changes modal and Save modal to update trailers',
      'Bux Fix: Add data parameter to /trailer-changes PUT route',
    ],
  },
  {
    version: '1.01.10',
    date: '22-12-14',
    updates: [
      'Feature: Update API route, split between trailer-changes/moves and trailer-changes/trips, add PUT route',
    ],
  },
  {
    version: '1.01.9',
    date: '22-12-05',
    updates: [
      'Feature: Add alert to display in trip details when trailer-change API returns trailer-change data.',
    ],
  },
  {
    version: '1.01.8',
    date: '22-12-05',
    updates: [
      'Feature: Add /moves/trailer-change API and created slice to store retrieved data.',
    ],
  },
  {
    version: '1.01.7',
    date: '2022-11-23',
    updates: [
      'Feature: Add additional shipping lines & add Shipping Line input field to Export Containers',
    ],
  },
  {
    version: '1.01.6',
    date: '2022-11-23',
    updates: [
      'Feature: Update container sizes.',
    ],
  },
  {
    version: '1.01.5',
    date: '2022-11-23',
    updates: [
      'Feature: Add missing info to Status dropdown and status to Filter By dropdown on Shipments Header',
    ],
  },
  {
    version: '1.01.4',
    date: '2022-11-22',
    updates: [
      'Feature: Reorder Moves Table, Route moved between Move and Action',
    ],
  },
  {
    version: '1.01.3',
    date: '2022-11-22',
    updates: [
      'Feature: Remove Type (Groups table & Drivers table) from dispatch board',
    ],
  },
  {
    version: '1.01.2',
    date: '2022-11-18',
    updates: [
      'Bug fix: Solve issue where a user could delete a trip or shipment and then attempt to save it at the same time',
    ],
  },
  {
    version: '1.01.1',
    date: '2022-01-10',
    updates: [
      'Feature: Add Latest Updates page',
    ],
  },
  {
    version: '1.01.0',
    date: '2022-01-10',
    updates: [
      'Feature: Move the Settings and Help Centre links to the top navigation bar',
      'Bug fix: Solve potential memory leak issue',
    ],
  },
];

export const VERSION_NO = updateArr[0].version;
