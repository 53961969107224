import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Container } from 'react-bootstrap';

import useAppDispatch from '../../hooks/useAppDispatch';

import Header from './Header';
import ShipmentsTable from './ShipmentsTable/ShipmentsTable';
import NoShipmentMsg from './ShipmentsTable/NoShipmentMsg';
import Loader from '../../components/Loader';

import {
  loadShipments,
  selectAreShipmentsLoading,
  selectShipments,
} from '../../redux/slices/shipments';
import { selectIsSettingsLoaded, selectShipmentBoardSettings } from '../../redux/slices/settings';

import { LOADING_TEXT } from '../boards/constants/boards.constants';
import UnassignedShipmentsTable from './ShipmentsTable/UnassignedShipmentsTable';
import { TableHeader } from './ShipmentsTable/models/models';

const NO_SHIPMENTS_MESSAGE = 'No shipments found';

interface Props {
  name?: string;
  shipmentTypeId?: string;
  headers: TableHeader[];
}

function ShipmentList({ name, shipmentTypeId, headers }: Props) {
  const dispatch = useAppDispatch();
  const [selectedShipments, setSelectedShipments] = useState<string[]>([]);
  const shipments = useSelector(selectShipments);
  const areShipmentsLoading = useSelector(selectAreShipmentsLoading);
  const boardSettings = useSelector(selectShipmentBoardSettings);
  const isBoardSettings = useSelector(selectIsSettingsLoaded);
  const title = name ? `${name} Shipments` : 'Shipments';
  useEffect(() => {
    if (isBoardSettings) {
      dispatch(loadShipments(boardSettings, shipmentTypeId));
    }
  }, [dispatch, isBoardSettings, shipmentTypeId]);

  return (
    <>
      <Helmet title={title} />
      <Container fluid className="p-0">
        <Header
          title={title}
          selectedShipments={selectedShipments}
          shipmentTypeId={shipmentTypeId}
        />
        {areShipmentsLoading && <Loader text={LOADING_TEXT} />}
        {!areShipmentsLoading && !shipmentTypeId && (
          <UnassignedShipmentsTable />
        )}
        {shipments.length > 0 && !areShipmentsLoading ? (
          <div className="mt-4">
            <ShipmentsTable
              headers={headers}
              noShipmentsMessage={NO_SHIPMENTS_MESSAGE}
              formattedShipments={shipments}
              selectedShipments={selectedShipments}
              setSelectedShipments={setSelectedShipments}
            />
          </div>
        ) : (
          <div className="mt-6">
            <NoShipmentMsg areShipmentsLoading={areShipmentsLoading} />
          </div>
        )}
      </Container>
    </>
  );
}

ShipmentList.defaultProps = {
  name: undefined,
  shipmentTypeId: undefined
};

export default ShipmentList;